import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import {
  RouterProvider,
  Link,
  createRouter,
  createHashHistory,
} from '@tanstack/react-router';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import './i18n';

// Import the generated route tree
import { routeTree } from './routeTree.gen';

// Create a new router instance
const router = createRouter({
  routeTree,
  history: createHashHistory(),
  defaultNotFoundComponent: () => {
    return (
      <div>
        <p>Not found!</p>
        <Link to='/'>Go home</Link>
      </div>
    );
  },
  context: {
    auth: undefined!, // This will be set after we wrap the app in an AuthProvider
  },
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  // eslint-disable-next-line no-unused-vars
  interface Register {
    router: typeof router
  }
}

function App () {
  const auth = useAuth0();
  return (
    <RouterProvider
      context={{ auth }}
      router={router}
    />
  );
}

// Render the app
const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <Auth0Provider
        authorizationParams={{
          redirect_uri: window.location.origin,
        }}
        clientId='n1bUEJ7K6STDDUKejlQ2aTFWK9kwuioY'
        domain='credit-pulse.us.auth0.com'
      >
        <App />
      </Auth0Provider>
    </StrictMode>,
  );
}
