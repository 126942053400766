import { useState, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { propEq, find } from 'rambda';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ReactCountryFlag from 'react-country-flag';

import LANGUAGES from '../../utils/language';

interface LanguageOption {
  text: string
  value: string
  countryCode?: string
};
interface LanguageSelectProps {
  options?: LanguageOption[]
};

const LanguageSelect = ({
  options = LANGUAGES,
}: LanguageSelectProps) => {
  const { t, i18n } = useTranslation('components', { keyPrefix: 'language-select' });
  const [ anchorElement, setAnchorElement ] = useState<null | Element>(null);
  const defaultValue = find(propEq(i18n.language, 'value'), options) || options[0];
  // const isRtl = RTL_LANGUAGE_CODES.includes(i18n.language);
  const handleSelectLanguage = (option: LanguageOption) => {
    i18n.changeLanguage(option.value);
    // for now, reload the page, since our components are not bound to this change for perf reasons
    // setTimeout(() => window.location.reload(), 1);
    handleCloseLanguageMenu();
  };
  const handleOpenLanguageMenu = (event: SyntheticEvent) => {
    setAnchorElement(event.currentTarget);
  };
  const handleCloseLanguageMenu = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <Tooltip title={t('select_language')}>
        <IconButton
          sx={{ p: 0 }}
          onClick={handleOpenLanguageMenu}
        >
          <ReactCountryFlag countryCode={defaultValue.countryCode || 'us'} />
        </IconButton>
      </Tooltip>
      <Menu
        keepMounted
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id='menu-appbar'
        open={Boolean(anchorElement)}
        slotProps={{
          paper: {
            style: {
              maxHeight: 300,
            },
          },
        }}
        sx={{ mt: '40px' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleCloseLanguageMenu}
      >
        {options.map((option) => (
          <MenuItem
            key={option.text}
            selected={defaultValue.value === option.value}
            onClick={() => handleSelectLanguage(option)}
          >
            <Typography sx={{ textAlign: 'center' }}>{option.text}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageSelect;

/* <Dropdown
      item
      scrolling
      direction='left'
      text={defaultValue.text}
    >
      <Dropdown.Menu>
        <Dropdown.Header
          content={t('common.language-select.select_language')}
          icon='world'
          style={(isRtl)
            ? {
                direction: 'rtl',
                textAlign: 'right',
              }
            : undefined}
        />
        <Dropdown.Divider />
        {options.map((option) => (
          <Dropdown.Item
            key={option.value}
            selected={defaultValue.value === option.value}
            text={option.text}
            value={option.value}
            onClick={handleSelectLanguage}
          />
        ),
        )}
      </Dropdown.Menu>
    </Dropdown> */
