import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const purple = '#556CD6';
const lightPurple = '#9292CC';
// const darkGreen = '#4A6662'
const green = '#afd5ac';
const lightGreen = '#f1f5ec';

const theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          color: 'black',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: purple,
            backgroundColor: 'white',
          },
          '&.Mui-selected:hover': {
            backgroundColor: lightGreen,
          },
          '&:hover': {
            backgroundColor: lightGreen,
          },
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        childLeaving: {
          color: green,
        },
        ripple: {
          color: green,
        },
        ripplePulsate: {
          color: green,
        },
        rippleVisible: {
          color: green,
        },
        child: {
          color: green,
        },
        childPulsate: {
          color: green,
        },
      },
    },
  },
  // https://mui.com/material-ui/customization/palette/
  palette: {
    primary: {
      main: purple,
    },
    secondary: {
      main: lightPurple,
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
